import React, { Suspense } from "react"

import { isSSR } from "../utils/constants"
import { colors } from "../utils/colors"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Spinner from "../components/Spinner"

const LegalTerms = React.lazy(() => import("../components/LegalTerms"))

export default () => {
  return (
    <>
      {!isSSR && (
        <Suspense fallback={<Spinner />}>
          <SEO
            title="Términos y condiciones"
            keywords={["Políticas de la empresa,políticas,privacidad,"]}
            description={["Política de Cookies "]}
          />
          <Layout background={colors.GraySolitude}>
            <LegalTerms title="Términos y condiciones">
              <p>
                Iban utiliza cookies para mejorar el desarrollo de la plataforma
                de inversión y brindarte una mejor experiencia. Este texto
                explica qué tipos de cookies usamos, por qué, y cómo
                identificarlas y eliminarlas
              </p>
              <p>
                Es importante tener en cuenta que al borrar las cookies actuales
                o deshabilitar las futuras, es posible que no seas capaz de
                acceder a ciertas áreas o características de nuestro sitio Web o
                de la plataforma de inversión
              </p>
              <p>¿Qué son las cookies?</p>
              <p>
                Las cookies son pequeños archivos de texto que se almacenan en
                tu ordenador, móvil o tablet, cuando visitas algún sitio Web.
                Las que utilizamos son incapaces de dañar tus dispositivos y
                nunca almacenamos información sensible en cookies
              </p>
              <p>
                Las cookies cumplen muchas funciones, pero de forma más general,
                las utilizadas en nuestra plataforma son utilizadas para:
              </p>
              <p>
                Reconocerte la próxima vez que nos visites, de manera que
                podamos proveer información relevante sobre Iban Identificar
                errores o un funcionamiento atípico de la plataforma de
                inversión ¿Qué cookies utiliza Iban?
              </p>
              <p>1. Cookies esenciales</p>
              <p>
                Algunas cookies son esenciales para el funcionamiento de la
                plataforma de inversión. Estas cookies nos permiten mantener tu
                sesión abierta mientras exploras distintas partes de nuestro
                sistema sin necesidad de ingresar tus datos cada vez que pasas a
                una nueva página. Sin ellas, los usuarios no serían capaces de
                iniciar sesión. Estas cookies no recopilan información acerca de
                ti que pudiera ser usada para fines comerciales o para
                identificar dónde has estado en Internet
              </p>
              <p>2. Cookies de desempeño</p>
              <p>
                Las cookies de desempeño recopilan información sobre cómo
                utilizas la plataforma de inversión. Por ejemplo, qué páginas
                visitas o si experimentas algún error. Estas cookies no
                recopilan ninguna información que pudiera identificarle. Toda la
                información recopilada es anónima y usada únicamente para
                mejorar el funcionamiento de la plataforma de inversión, para
                ayudarnos a entender qué le interesa a nuestros usuarios, y para
                medir qué tan efectivas resultan nuestras comunicaciones.
                Algunas de las cookies de desempeño que utilizamos son parte de
                los servicios prestados por terceros, como Google Analytics
              </p>
              <p>3. Cookies de funcionalidad</p>
              <p>
                Las cookies de funcionalidad son utilizadas para proveer
                servicios o para recordar configuraciones y así mejorar tus
                visitas a la plataforma de inversión
              </p>
              <p>4. Cookies de dirección</p>
              <p>
                Las cookies de dirección están enlazadas con servicios prestados
                por terceros tales como los botones de “me gusta” o “compartir”
                en Redes Sociales
              </p>
              <p>
                Las cookies de dirección son utilizadas para enlazar servicios
                de Redes Sociales como Facebook, que podrían, llegado el caso,
                utilizarla información sobre tu visita para mostrarte publicidad
                en otros sitios Web
              </p>
              <p>
                Las cookies de dirección también informan sobre tus visitas a
                agencias publicitarias de forma que puedan presentarle
                publicidad relacionada. Por ejemplo, si visitas otras páginas de
                inversión, querrán mostrarle un mensaje sobre inversiones en
                lugar de préstamos
              </p>
              <p>
                Tienes el control del uso de estas cookies pero detenerlas
                podría evitar que te ofrezcamos algunos servicios. En aquellas
                de cuya gestión se encargan terceros, puedes utilizar de forma
                alternativa las propias herramientas de los terceros para
                prevenir esas cookies
              </p>
              <p>¿Cómo desactivar las cookies?</p>
              <p>
                Si deseas restringir o bloquear por completo las cookies del
                navegador, deberás dirigirte a la configuración del propio
                navegador y a través de la función de Ayuda debería indicarte el
                modo de hacerlo. Alternativamente puedes visitar
                aboutcookies.org o “WikiHow - Limpia las cookies de tu
                navegador” que contienen información al respecto
              </p>
            </LegalTerms>
          </Layout>
        </Suspense>
      )}
    </>
  )
}
