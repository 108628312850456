export const colors = {
  Black: "#000000",
  BlackEbony: "#313337",
  BlackFiord: "#55595C",
  BlackMontana: "#373A3C",
  BlueEndeavour: "#1E4887",
  BlueTopaz: "#2A5082",
  GreenCaribbean: "#00C986",
  GreenJade: "#00B77A",
  GraySolitude: "#f0f1f3",
  GreenCaribbeanGradient:
    "linear-gradient(136.55deg, #138042 -23.14%, #00C986 91.24%)",
  GreenFern: "#5CB85C",
  GhostWhite: "#F5F6FF",
  White: "#FFFFFF",
  Midnight: "#232735",
  LinkWater: "#d3d4d8",
}
