import React from "react"
import { Spinner } from "react-bootstrap"
import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .spinner-border {
    width: 10rem;
    height: 10rem;
  }
`
export default () => {
  return (
    <Container>
      <Spinner animation="border" variant="success" />
    </Container>
  )
}
